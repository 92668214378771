import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBAnimation, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import ColumnAlternating from '../components/columnAlternatingStyled'
import CardIcons from '../components/cardIcons.js'
import Showdown from 'showdown'

const Services = ({ data, location }) => {
    const post = data.markdownRemark
    const converter = new Showdown.Converter()  

    return (
      <Layout>
       <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post?.frontmatter?.image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
        />

        <Hero
         class="intro-65"
         image={post.frontmatter.image.childImageSharp.gatsbyImageData}
         foreimage={null}
         alt={post.frontmatter.alttext}
         title={post.frontmatter.title}
         subtitle={post.frontmatter.subtitle}
         ctatext={post.frontmatter.ctatext}
         ctaslug={post.frontmatter.ctaslug}         
         type="root"
        />
      </div>

    <main>
        <section className="bg-white">
        <MDBContainer>
            <MDBRow>
              <MDBCol>
              <div
                className={`font-w-400 text-xs-extra-large divlink ${post.html ? 'text-opening-para' : 'text-opening-para-mb0'}`}
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.openingpara), }}
              />

            {post.html && (
              <div
                className="richtext divlink"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            )}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.bragroll && (
        <section className="bg-white pt-0 mt-n4">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                {post.frontmatter.bragroll.title}
              </h2>
              {post.frontmatter.bragroll.card && (
                <MDBRow>
                  <>
                    {post.frontmatter.bragroll.card.map((cards, index) => (
                        <CardIcons
                          key={index}
                          collg="4"
                          colmd="6"
                          title={cards.title}
                          subtitle={cards.subtitle}
                          image={cards.image.childImageSharp.gatsbyImageData}
                          alt={cards.alttext}
                        />
                      ))}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}              

        <>  
        {post.frontmatter.section &&
          <section className="bg-white-grey-gradient">
            <MDBAnimation type="fadeIn" duration="1587ms">
              <MDBContainer>
                <div className="about-overview pt-5">
                  {post.frontmatter.section.map((sections, index) => (
                      <ColumnAlternating
                        key={index}
                        title={sections.title}
                        subtitle={sections.subtitle}
                        subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                        description={sections.description}
                        image={sections.image.childImageSharp.gatsbyImageData}
                        alt={sections.alttext}
                        placement={sections.placement}
                        titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                        link={sections.btnlink}
                        colour={sections.btncolour}
                      />
                    ))}
                </div>
              </MDBContainer>
            </MDBAnimation>
          </section>
        } 
        </>


    </main>
  </Layout>
    )
}
export default Services

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }      
    markdownRemark(
      fields: {}
      frontmatter: { template: { eq: "other" }, name: { eq: "Services" } }
    ) {          
      html
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        ctatext
        ctaslug        
        openingpara
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        bragroll {
          title
          card {
            title
            subtitle
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
          }
        }          
        section {
            title
            subtitle
            subtitlecolour
            description
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, quality: 90)
              }
            }
            alttext
            placement
            btnlink
            btncolour
          }
      }
    }
  }
`